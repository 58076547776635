<div class="body-dropdown-wrapper dropdown-align-{{alignment}} d-flex"
     (clickOutside)="close()">
    <ng-select [ngModel]="selectedDatesOptions"
               (clear)="clearDates()"
               (remove)="removeDate($event)"
               [items]="selectedDatesOptions"
               (click)="open()"
               [multiple]="true"
               [isOpen]="false"
               [disabled]="disabled"
               class="w-100">
    </ng-select>
    <div class="body-dropdown-content"
         [hidden]="!datepickerOpen">
        <datepicker #datepicker
                    (dateSelectChange)="onSelectDates($event)"
                    (click)="$event.stopPropagation()"
                    [dayTemplate]="customDay"
                    [format]="format"
                    [disabled]="disabled">
        </datepicker>
    </div>
</div>

<ng-template #customDay
             let-date
             let-disabled="disabled"
             let-focused="focused">
        <span class="custom-day"
              [class.focused]="focused"
              [class.bg-primary]="isDateSelected(date)"
              [class.text-white]="isDateSelected(date)"
              [class.text-muted]="disabled">
            {{ date.day }}
        </span>
</ng-template>
