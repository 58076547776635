import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {fromNgbDateStructTo, toNgbDateStructFrom} from '../../../../utils/date.utils';

type DateOptions = {id: string; label: string; value: NgbDate};

@Component({
    selector: 'app-datepicker-multi-select',
    templateUrl: './datepicker-multi-select.component.html',
    styleUrl: './datepicker-multi-select.component.scss',
})
export class DatepickerMultiSelectComponent {
    @Input() alignment: 'left' | 'right' = 'right';
    @Input() date: string;
    @Input() format: 'date' | 'dateTime' | 'ngbDate' | 'text' = 'text';
    @Input() disabled: boolean;
    @Output() datesChange = new EventEmitter<string[]>();
    datepickerOpen: boolean;
    selectedDates: NgbDate[] = [];
    selectedDatesOptions: DateOptions[] = [];
    selectedDatesSet: Set<string> = new Set();

    ngOnInit() {
        this.initializeSelectedDates();
    }

    initializeSelectedDates() {
        if (this.date) {
            const initialDate = NgbDate.from(toNgbDateStructFrom(false).text(this.date));
            if (initialDate) {
                this.onSelectDates(initialDate);
            }
        }
    }

    close() {
        this.datepickerOpen = false;
    }

    open() {
        setTimeout(() => this.datepickerOpen = true);
    }

    updateDatesOptions() {
        this.selectedDatesOptions = this.selectedDates.map(selectedDate => {
            const dateText = fromNgbDateStructTo(false).text(selectedDate);
            return {id: dateText, label: dateText, value: selectedDate};
        });
        this.datesChange.emit(this.selectedDatesOptions.map(x => x.id));
    }

    onSelectDates(event: NgbDate) {
        const dateKey = `${event.year}-${event.month}-${event.day}`;
        if (this.selectedDatesSet.has(dateKey)) {
            this.selectedDatesSet.delete(dateKey);
            this.selectedDates = this.selectedDates.filter(x => !x.equals(event));
        } else {
            this.selectedDatesSet.add(dateKey);
            this.selectedDates.push(event);
        }
        this.updateDatesOptions();
    }

    isDateSelected(date: NgbDate): boolean {
        return this.selectedDatesSet.has(`${date.year}-${date.month}-${date.day}`);
    }

    removeDate(event: DateOptions) {
        this.onSelectDates(event.value);
    }

    clearDates() {
        this.selectedDates = [];
        this.selectedDatesOptions = [];
        this.selectedDatesSet.clear();
        this.updateDatesOptions();
    }
}
